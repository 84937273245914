import React from 'react';
import cx from 'classnames';
import useMediaQuery from '@frontastic-engbers/helpers/hooks/useMediaQuery';
import * as screenSizes from '@frontastic-engbers/helpers/utils/screensizes';
import { Cell as LayoutElement } from './cell';
import { highlightClassNames, TasticWrapper } from './component';
import { Errors } from './errors';
import { Grid } from './grid';
import { Cell as LayoutElementType, CellConfiguration, PageDataResponse, PagePreviewDataResponse, Tastic, TasticRegistry } from './types';
import { useRouter } from 'next/router';
const VARIANTS = {
  initial: {
    y: -8,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1
  },
  exit: {
    y: -8,
    opacity: 0
  }
};
export function FrontasticRenderer({
  data,
  tastics = {},
  gridClassName,
  wrapperClassName,
  mainClassName,
  currentHighlight
}: {
  data: PageDataResponse & PagePreviewDataResponse;
  tastics: TasticRegistry;
  gridClassName?: string;
  wrapperClassName?: string;
  mainClassName?: string;
  currentHighlight?: string;
}) {
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const {
    asPath
  } = useRouter();
  const isCheckout = asPath.includes('/checkout/login') ? false : asPath.includes('checkout');
  function deviceVisibility(conf: CellConfiguration) {
    return `${conf.mobile ? 'block' : 'hidden'} ${conf.tablet ? 'md:block' : 'md:hidden'} ${conf.desktop ? 'lg:block' : 'lg:hidden'}`;
  }
  if (data?.page?.sections?.kit) {
    return <Grid wrapperClassName="w-full">
        {data?.page?.sections?.kit?.layoutElements.map((layoutElement: LayoutElementType) => <LayoutElement size={layoutElement.configuration.size} className={highlightClassNames(currentHighlight === layoutElement.layoutElementId)} key={layoutElement.layoutElementId}>
            {layoutElement.tastics.map(t => <TasticWrapper tastics={tastics} key={t.tasticId} data={t} dataSources={data.data.dataSources} pageFolder={data.pageFolder} highlight={currentHighlight === t.tasticId} previewId={data?.previewId} />)}
          </LayoutElement>)}
      </Grid>;
  }
  return <div className={`flex flex-col items-stretch justify-start ${isCheckout ? 'is-checkout' : ''}`} data-sentry-component="FrontasticRenderer" data-sentry-source-file="renderer.tsx">
      {process && process.env.NODE_ENV !== 'production' && <Errors />}
      <Grid gridClassName={gridClassName} wrapperClassName={`${wrapperClassName} w-full ${highlightClassNames(currentHighlight === 'head')}`} data-sentry-element="Grid" data-sentry-source-file="renderer.tsx">
        {data?.page?.sections?.head?.layoutElements.map((layoutElement: LayoutElementType) => <LayoutElement size={layoutElement.configuration.size} className={cx(highlightClassNames(currentHighlight === layoutElement.layoutElementId), deviceVisibility(layoutElement.configuration))} key={layoutElement.layoutElementId}>
            {layoutElement.tastics.map(t => <TasticWrapper tastics={tastics} key={t.tasticId} data={t} dataSources={data.data.dataSources} pageFolder={data.pageFolder} highlight={currentHighlight === t.tasticId} previewId={data?.previewId} />)}
          </LayoutElement>)}
      </Grid>
      {/* <motion.div variants={VARIANTS} initial="initial" animate="animate" exit={'exit'}> */}
      <main id="mainAreaWrap">
        <Grid gridClassName={`${gridClassName} min-h-[90vh]`} wrapperClassName={`${wrapperClassName} ${mainClassName} w-full grow ${highlightClassNames(currentHighlight === 'main')}`} data-sentry-element="Grid" data-sentry-source-file="renderer.tsx">
          {data?.page?.sections?.main?.layoutElements.map((layoutElement: LayoutElementType) => {
          return <LayoutElement
          // Original was:
          // size={isBiggerThanMobile ? layoutElement.configuration.size : 12}
          // We changed this to enable columns on mobile.
          size={layoutElement.configuration.size} className={cx(highlightClassNames(currentHighlight === layoutElement.layoutElementId), deviceVisibility(layoutElement.configuration))} key={layoutElement.layoutElementId}>
                {layoutElement.tastics.map((t: Tastic) => <TasticWrapper tastics={tastics} key={t.tasticId} data={t} dataSources={data.data.dataSources} pageFolder={data.pageFolder} highlight={currentHighlight === t.tasticId} previewId={data?.previewId} />)}
              </LayoutElement>;
        })}
        </Grid>
      </main>
      {/* </motion.div> */}
      <Grid gridClassName={gridClassName} wrapperClassName={`${wrapperClassName} footer w-full ${highlightClassNames(currentHighlight === 'footer')}`} data-sentry-element="Grid" data-sentry-source-file="renderer.tsx">
        {data?.page?.sections?.footer?.layoutElements.map((layoutElement: LayoutElementType) => <LayoutElement size={layoutElement.configuration.size} className={cx(highlightClassNames(currentHighlight === layoutElement.layoutElementId), deviceVisibility(layoutElement.configuration))} key={layoutElement.layoutElementId}>
            {layoutElement.tastics.map((t: Tastic) => <TasticWrapper tastics={tastics} key={t.tasticId} data={t} dataSources={data.data.dataSources} pageFolder={data.pageFolder} highlight={currentHighlight === t.tasticId} previewId={data?.previewId} />)}
          </LayoutElement>)}
      </Grid>
    </div>;
}